import * as React from 'react'
import { useState } from "react"
import { Link, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx'

import NavbarLayout from '../components/navbarLayout'
import FooterLayout from '../components/footerLayout'

const museesPage = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <body>
      <NavbarLayout> </NavbarLayout>
        
      <section className="museums" style={{marginTop:100}}>
        <div className="wrap">
          <MDXRenderer>{ data.museums.body }</MDXRenderer>
          <div className="museums-container">
            <a className="item">
              <Link to="/espaceAngouleme"><StaticImage src="../assets/img/polaroid-angouleme.png" /></Link>
              <Link to="/espaceAngouleme"><h4>Angoulême</h4></Link>
            </a>
            <a className="item">
              <Link to="/espaceLaRochelle"><StaticImage src="../assets/img/polaroid-la-rochelle.png" /></Link>
              <Link to="/espaceLaRochelle"><h4>La Rochelle</h4></Link>
            </a>
          </div>
        </div>
      </section> 
      
      <FooterLayout></FooterLayout>
    </body>  
  )
}

export const query = graphql`
  query {
    header: mdx(slug: { eq: "header" }) {
      body
    }
    intro: mdx(slug: { eq: "intro" }) {
      body
    }
    museums: mdx(slug: { eq: "museums" }) {
      body
    }
    actualites: allActualites {
      totalCount
      nodes {
        title
        description
        image
      }
    }
  }
`

export default museesPage
